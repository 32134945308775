// React
import React, { useState, useEffect } from 'react';

// Packages
import cc from 'classcat';
import { da } from 'date-fns/locale';
import t from 'prop-types';

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import { useFormContext, useController } from 'react-hook-form';

import { parse as fnsParse } from 'date-fns';
import dayjs from 'dayjs';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

// Utilities
import { useLabels, useContext } from 'utilities/hooks';

const DatePickerComponent = ({
    controller,
    defaultValue,
    name,
    object,
    disabled,
    theme,
    unregisterInput,
}) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const { LOCALE } = useContext();

    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
        defaultValue: defaultValue,
        rules: {
            required,
            validate: {
                isDate: v => (v ? dayjs(v).isValid() : true),
            },
        },
    });

    const formContext = useFormContext();

    // ///////////////////
    // STATE
    // ///////////////////

    const [showCalendar, setShowCalendar] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [inputDate, setInputDate] = useState(defaultValue);
    const [date, setDate] = useState(
        defaultValue ? fnsParse(defaultValue, 'y-MM-dd', new Date()) : null
    );

    // ///////////////////
    // METHODS
    // ///////////////////

    function handleInputChange(event) {
        // Get value
        const value = event.currentTarget.value;

        // Always just set input date
        setInputDate(value);

        // Check if date is valid format and remove input error
        if (
            dayjs(value, 'YYYY-MM-DD', true).isValid() ||
            dayjs(value, 'YYYY-MM-D', true).isValid() ||
            dayjs(value, 'YYYYMMDD', true).isValid() ||
            dayjs(value, 'YYYYMMD', true).isValid()
        ) {
            setInputError(false);
        }
        // If not valid, add input error
        else {
            setInputError(true);
        }
    }

    function handleInputKeyDown(event) {
        // Show calendar on arrow down
        if (event.key === 'ArrowDown') {
            setShowCalendar(true);
            return;
        }

        // Hide calendar on arrow up
        if (event.key === 'ArrowUp') {
            setShowCalendar(false);
            return;
        }

        // Check for enter key and no input error
        if ((event.key === 'Enter' || event.key === 'Tab') && !inputError) {
            // Get value
            // Replace all dashes with empty string
            const inputValue = event.currentTarget.value.replaceAll('-', '');

            // If no input value just return
            if (!inputValue) {
                return;
            }

            // Update calendar date value (parsed with date-fns)
            const parsedDate = fnsParse(inputValue, 'yMMdd', new Date());

            // Check for valid date with dayjs
            if (!dayjs(parsedDate).isValid()) {
                return;
            }

            // Format date to correct format
            const formattedDate = dayjs(parsedDate).format('YYYY-MM-DD');

            // Update calendar date value
            setDate(parsedDate);

            // Update input date in correct format
            setInputDate(formattedDate);

            // Update form value in correct format
            onChange(formattedDate);

            // Hide calendar and blur
            setShowCalendar(false);
            event.currentTarget.blur();
            return;
        }

        // Check for escape key
        if (event.key === 'Escape') {
            // Reset to previous known date selected on calendar
            setInputDate(dayjs(date).format('YYYY-MM-DD'));

            // Remove input error
            setInputError(false);

            // Hide calendar and blur
            setShowCalendar(false);
            event.currentTarget.blur();
            return;
        }
    }

    function handleInputBlur(event) {
        // Only run if there calendar is hidden and no input
        if (!showCalendar && !inputError) {
            // Get value
            // Replace all dashes with empty string
            const inputValue = event.currentTarget.value.replaceAll('-', '');

            // If no input value just return
            if (!inputValue) {
                return;
            }

            // Update calendar date value (parsed with date-fns)
            const parsedDate = fnsParse(inputValue, 'yMMdd', new Date());

            // Check for valid date with dayjs
            if (!dayjs(parsedDate).isValid()) {
                return;
            }

            // Format date to correct format
            const formattedDate = dayjs(parsedDate).format('YYYY-MM-DD');

            // Update calendar date value
            setDate(parsedDate);

            // Update input date in correct format
            setInputDate(formattedDate);

            // Update form value in correct format
            onChange(formattedDate);

            // Hide calendar and blur
            setShowCalendar(false);
            event.currentTarget.blur();
        }
    }

    function handleOnDayClick(event) {
        // Update calendar date value
        setDate(event);

        // Update input date in correct format
        setInputDate(dayjs(event).format('YYYY-MM-DD'));

        // Update form value in correct format
        onChange(dayjs(event).format('YYYY-MM-DD'));

        // Hide calendar
        setShowCalendar(false);
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (formContext && unregisterInput) {
            formContext.unregister(name);
        }
    }, [unregisterInput]);

    useEffect(() => {
        if (value) {
            setInputDate(dayjs(value).format('YYYY-MM-DD'));
            setDate(fnsParse(value, 'y-MM-dd', new Date()));
        }
    }, []);

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isBlueAlt = theme === 'blue-alt';
    const isTeal = theme === 'teal';

    const twTheme = {
        'blue-alt': {
            300: 'hsl(231, 50%, 50%)',
            10: 'hsl(231, 17%, 96%)',
        },
        blue: {
            300: 'hsl(231, 50%, 50%)',
            10: 'hsl(231, 17%, 96%)',
        },
        teal: {
            300: 'hsl(203, 50%, 50%)',
            10: 'hsl(203, 17%, 96%)',
        },
    };

    // ///////////////////
    // RENDER
    // ///////////////////

    return !unregisterInput ? (
        <>
            <label className="flex flex-col" htmlFor={name}>
                {inputLabel && (
                    <span
                        className={cc([
                            'input-label',
                            {
                                'input-label-blue-alt': isBlueAlt,
                                'input-label-blue': isBlue,
                                'input-label-teal': isTeal,
                            },
                        ])}>
                        {inputLabel}
                        {required && (
                            <span>({label('FormCaptureRequired')})</span>
                        )}
                    </span>
                )}
                {subLabel && (
                    <span
                        className={cc([
                            'mt-8 input-sublabel',
                            {
                                'input-sublabel-blue-alt': isBlueAlt,
                                'input-sublabel-blue': isBlue,
                                'input-sublabel-teal': isTeal,
                            },
                        ])}>
                        {subLabel}
                    </span>
                )}

                <div className="relative flex flex-col">
                    <input
                        type="text"
                        disabled={disabled}
                        onClick={() => setShowCalendar(true)}
                        onChange={event => handleInputChange(event)}
                        onKeyDown={event => handleInputKeyDown(event)}
                        onBlur={event => handleInputBlur(event)}
                        value={inputDate}
                        placeholder="yyyy-mm-dd"
                        className={cc([
                            'input-defaults',
                            {
                                'input-defaults-blue-alt': isBlueAlt,
                                'input-defaults-blue': isBlue,
                                'input-defaults-teal': isTeal,
                                'input-defaults-error': error || inputError,
                                'mt-16': inputLabel,
                            },
                        ])}
                    />
                    {showCalendar && (
                        <DayPicker
                            locale={LOCALE === 'da' ? da : null}
                            className={cc([
                                '!mt-16',
                                {
                                    'text-teal-100': isTeal,
                                    'text-blue-100': isBlue || isBlueAlt,
                                },
                            ])}
                            selected={date}
                            defaultMonth={
                                date
                                    ? new Date(
                                          dayjs(date).format('YYYY'),
                                          dayjs(date).format('M') - 1
                                      )
                                    : null
                            }
                            onDayClick={event => handleOnDayClick(event)}
                        />
                    )}
                </div>

                {error && (
                    <div className="flex mt-6 -mb-16 input-utility-text">
                        {error && (
                            <div className="input-utility-text-error">
                                {errorLabel}
                            </div>
                        )}
                    </div>
                )}
            </label>
            <style global jsx>
                {`
                    .rdp-day {
                        padding-top: 5px;
                    }
                    .rdp {
                        margin: 0;
                        --rdp-accent-color: ${twTheme[theme][300]};
                        --rdp-background-color: ${twTheme[theme][10]};
                    }
                `}
            </style>
        </>
    ) : null;
};

DatePickerComponent.propTypes = {
    controller: t.object.isRequired,
    defaultValue: t.string,
    name: t.string.isRequired,
    object: t.object.isRequired,
    disabled: t.bool,
    theme: t.oneOf(['teal', 'blue', 'blue-alt']),
};

DatePickerComponent.defaultProps = {
    theme: 'teal',
};

export default DatePickerComponent;
